import React from 'react'

const Refer = () => {
  return (
    <>
      <div style={{ fontSize: "40px", marginTop: "200px" }}>Refer</div>
      <div style={{ fontSize: "40px", marginTop: "10px" }}>Coming Soon...</div>
    </>

  )
}

export default Refer