import NCEImage from "../assets/NCE.jpg";

const NCE = () => {
  return (
    <div>
      <img src={NCEImage} alt="NCE image" />
    </div>
  );
};

export default NCE;
