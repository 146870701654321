import classes from './styles/Homepage.module.css';
import Button from './components/Button';
import Ticker from './components/Ticker';
import FPContent from './components/FP-Content';
import Banner from './components/Banner';
import FPContentBelow from './components/FP-Content-Below';

const Homepage = () => {
  return (
    <>
      <Ticker />
      <header className={"header"}>
        <Banner />
      </header>
      <FPContent />
      <div className={classes["grids-container"]}>
        <div className={classes["content-container"]}>
          <Button
            title={"Join"}
            action={"Member/Merchant"}
            // path={"/join"}
            path={"/sign-in-up"}
            icon="/../public/assets/crowd.png"
            bgColor=""
          ></Button>
          <Button
            title={"Shop"}
            action={"Member/Non-Member"}
            // path={"https://fotm.thanksagain.com/"}
            path={"/"}
            icon="/../public/assets/deal.png"
            bgColor=""
          ></Button>
          <Button
            title={"Invite"}
            action={"Friends/Merchants"}
            // path={"/invite"}
            path={"/sign-in-up"}
            icon="/../public/assets/partners.png"
            bgColor=""
          ></Button>
          <Button
            title={"Refer"}
            action={"Customer to Merchant"}
            // path={"/refer"}
            path={"/sign-in-up"}
            icon="/../public/assets/referral.png"
            bgColor=""
          ></Button>
          <Button
            title={"Give"}
            action={"Advocates"}
            // path={"/give"}
            path={"/sign-in-up"}
            icon="/../public/assets/loving.png"
            bgColor=""
          ></Button>
          <Button
            title={"Invest"}
            action={"Money/Time/Ideas"}
            // path={"/invest"}
            path={"/sign-in-up"}
            icon="/../public/assets/stockmarket.png"
            bgColor=""
          ></Button>
        </div>
      </div>
      <FPContentBelow />
    </>
  )
}

export default Homepage