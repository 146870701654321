import React from 'react'

const Invest = () => {
  return (
    <>
      <div style={{ fontSize: "40px", marginTop: "200px" }}>Invest</div>
      <div style={{ fontSize: "40px", marginTop: "10px" }}>Coming Soon...</div>
    </>
  )
}

export default Invest