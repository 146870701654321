import '../styles/globals.css';
import Nav from './nav/MainNav'
import Footer from './Footer'
import classes from '../styles/Layout.module.css';

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
//   console.log("layout console log");
  return (
    <html lang="en">
      <body>
        <div className={classes.body}>
          <Nav />
          <main className={classes.main}>{children}</main>
          <footer className={classes.footer}>
            <Footer />
          </footer>
        </div>
      </body>
    </html>
  );
}
