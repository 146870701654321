import classes from "../styles/FP-Card.module.css";
import useCheckMobileScreen from "../hooks/checkmobile";
import MLKImage from "../assets/MLKJr.jpg";
import corpAmericaImage from "../assets/corpAmerica.jpg";
import OMUImage from "../assets/omu.jpg";
import togetherImage from "../assets/together.jpg";
import { Link } from "react-router-dom";

type MyJSXElement = JSX.Element;

interface MyComponentProps {
  id: number;
  header: string;
  content: MyJSXElement;
  image: string;
  alt: string;
  link: string;
}

interface MyComponentProps2 {
  id: number;
  header: string;
  content: MyJSXElement;
}

const EvenCard = ({ header, content, image, alt, link }: MyComponentProps) => {
  return (
    <div className={classes["container"]}>
      <Link
        to={
          header === ""
            ? "/BWSPDF"
            : header === '"Our Money United" Campaign'
              ? "/OMU"
              : header === "TOGETHER = IMPACT"
                ? "/mission"
                : header === "Putting Corporate America on Notice"
                  ? "/NCE"
                  : "#"
        }
        className={classes["link-container"]}
      >
        <img
          src={
            header === ""
              ? MLKImage
              : header === '"Our Money United" Campaign'
                ? OMUImage
                : header === "TOGETHER = IMPACT"
                  ? togetherImage
                  : corpAmericaImage
          }
          alt={alt}
          className={classes["pic"]}
          width={1000}
          height={1000}
        />
      </Link>
      <div className={classes["content-container"]}>
        <h1 className={classes.header}>{header ? header : ""}</h1>
        <div className={classes["content"]}>{content}</div>
        <br />
        <div className={classes["learn-more-button-container"]}>
          <Link
            to={
              header === ""
                ? "/BWSPDF"
                : header === '"Our Money United" Campaign'
                  ? "/OMU"
                  : header === "TOGETHER = IMPACT"
                    ? "/mission"
                    : header === "Putting Corporate America on Notice"
                      ? "/NCE"
                      : "#"
            }
            className={classes["learn-more-button"]}
          >
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
};

const OddCard = ({ header, content, image, alt, link }: MyComponentProps) => {
  return (
    <div className={classes["container"]}>
      <div className={classes["content-container"]}>
        <h1 className={classes.header}>{header ? header : ""}</h1>
        <div className={classes["content"]}>{content}</div>
        <br />
        <div className={classes["learn-more-button-container"]}>
          <Link
            to={
              header === ""
                ? "/BWSPDF"
                : header === '"Our Money United" Campaign'
                  ? "/OMU"
                  : header === "TOGETHER = IMPACT"
                    ? "/mission"
                    : header === "Putting Corporate America on Notice"
                      ? "/NCE"
                      : "#"
            }
            className={classes["learn-more-button"]}
          >
            Learn More
          </Link>
        </div>
      </div>
      <Link
        to={
          header === ""
            ? "/BWSPDF"
            : header === '"Our Money United" Campaign'
              ? "/OMU"
              : header === "TOGETHER = IMPACT"
                ? "/mission"
                : header === "Putting Corporate America on Notice"
                  ? "/NCE"
                  : "#"
        }
        className={classes["link-container"]}
      >
        <img
          src={
            header === ""
              ? MLKImage
              : header === '"Our Money United" Campaign'
                ? OMUImage
                : header === "TOGETHER = IMPACT"
                  ? togetherImage
                  : corpAmericaImage
          }
          alt={alt}
          className={classes["pic"]}
          width={500}
          height={500}
        />
      </Link>
    </div>
  );
};

const Card2 = ({ id, header, content }: MyComponentProps2) => {
  return (
    <div className={classes["container"]}>
      <div className={classes["content-container"]}>
        <h1 className={classes.header}>{header ? header : ""}</h1>
        <div className={classes["content"]}>{content}</div>
        <br />
        <div className={classes["learn-more-button-container"]}>
          <Link
            to={
              header === "Pledge and Education"
                ? "/NCE"
                : header === "Search, Shop, Refer, Give and Invest"
                  ? "/NCE"
                  : header === "Spending Ticker and Index"
                    ? "/NCE"
                    : "#"
            }
            className={classes["learn-more-button"]}
          // target="_blank"
          >
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
};

const FPCard = ({
  id,
  header,
  content,
  image,
  alt,
  link,
}: MyComponentProps) => {
  console.log(id);

  const mobile = useCheckMobileScreen();

  console.log(mobile);

  if (mobile) {
    const CardComponent = EvenCard;
    return (
      <CardComponent
        id={id}
        header={header}
        content={content}
        image={image}
        alt={alt}
        link={link}
      />
    );
  } else {
    const CardComponent = id % 2 === 0 ? EvenCard : OddCard;
    return (
      <CardComponent
        id={id}
        header={header}
        content={content}
        image={image}
        alt={alt}
        link={link}
      />
    );
  }
};

const FPCard2 = ({ id, header, content }: MyComponentProps) => {
  console.log(id);
  return <Card2 id={id} header={header} content={content} />;
};

export { FPCard, FPCard2 };
