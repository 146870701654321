import React from "react";

const MC_Template = () => {
    console.log("MC Template");
  
    return (
      <>
        <h1>[MCAtlas:TitleContent]</h1>
        <p>[MCAtlas:BodyContent]</p>
      </>
    );
  };
  
export default MC_Template;