import BWSImage from "../assets/BWS.pdf";
import classes from '../styles/PDFStyles.module.css';

const BWSPDF = () => {
  return (
    <div className={classes.pdf}>
      <embed
        src={BWSImage}
        width="1200px"
        height="800px"
        type="application/pdf"
      />
    </div>
  );
};

export default BWSPDF;
