import classes from '../styles/Footer.module.css';
import FooterNav from './nav/FooterNav';
import logo from "../assets/logo.png";
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {

  return (
    <div
      className={classes["footer-container"]
      }
    >
      <a href="https://www.fotmglobal.com">
        <img src={logo} alt="logo" className={classes.logo} />
      </a>
      <div className={classes["socials-container"]}>
        <a
          href={"https://instagram.com/fotmglobal?igshid=YmMyMTA2M2Y="}
          target="_blank"
        >
          <FontAwesomeIcon icon={faInstagram} className={classes.igIcon} />
        </a>
        <a
          href={"https://www.facebook.com/blackwallstreetcard?mibextid=LQQJ4d"}
          target="_blank"
        >
          <FontAwesomeIcon icon={faFacebook} className={classes.fbIcon} />
        </a>
      </div>
      <FooterNav />
    </div>
  );
};

export default Footer;
