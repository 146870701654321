import classes from '../styles/FP-Content.module.css';
import dvwIcon from "../assets/dvw.png";

const FPContent = () => {
  return (
    <div className={classes["fp-container"]}>
      <div className={classes["middle-container"]}>
        <h2 className={classes["middle-container-header2"]}>
          Building the New Conscious Economy, TOGETHER!
        </h2>
        {/* <div className={classes["shop-as-container"]}>
          <div className={classes["search1"]}>
            <a href="#" className={classes["shop-as"]}>
              Shop Now- Free
            </a>
            <div>
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className={classes.icon}
              />
            </div>
          </div>
          <div className={classes["search2"]}>
            <a href="#" className={classes["shop-as"]}>
              Shop Now [Members ONLY]</a>
              <div>
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className={classes.icon}
                />
              </div>
          </div>
        </div> */}
        <span className={classes["dvw-container"]}>
          <img src={dvwIcon} alt="dvw icon" className={classes["dvw-icon"]} />
          {/* <FontAwesomeIcon icon={faWallet} style={{color: "#ffffff",}} className={classes["dvw-icon"]}/> */}
          <h2>Digital Voter Wallet</h2>
        </span>
        <h3 className={classes.italics}>
          Vote & Track Every Dollar, Everyday, Everywhere
        </h3>
        <h2>
          Coming Soon...
        </h2>
      </div>
    </div>
  );
};

export default FPContent;
