import classes from '../../styles/FooterNav.module.css';

const FooterNav = () => {
  return (
    <div className={classes["footer-nav-container"]}>
      <a href="#" className={classes["footer-nav-link"]}>Terms of Service</a>
      <a href="#" className={classes["footer-nav-link"]}>Privacy Policy</a>
    </div>
  )
}

export default FooterNav