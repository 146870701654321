import "./App.css";
import Homepage from "./Homepage";
import RootLayout from "./components/Layout";
import { Route, Routes } from "react-router-dom";
import GetInvolved from "./pages/Get-Involved";
import Invest from "./pages/Invest";
import Invite from "./pages/Invite";
import Join from "./pages/Join";
import MC_Template from "./pages/MC_Template";
import OurWork from "./pages/Our-Work";
import Pledge from "./pages/Pledge";
import Refer from "./pages/Refer";
import Shop from "./pages/Shop";
import SignInUp from "./pages/Sign-In-Up";
import Vote from "./pages/Vote";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Give from "./pages/Give";
import BWSPDF from "./pages/BWSPDF";
import MissionPDF from "./pages/MissionPDF";
import NCE from "./pages/NCE";
import OMUPDF from "./pages/OMUPDF";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <RootLayout>
              <Homepage />
            </RootLayout>
          }
        />
        <Route
          path="/get-involved"
          element={
            <RootLayout>
              <GetInvolved />
            </RootLayout>
          }
        />
        <Route
          path="/invest"
          element={
            <RootLayout>
              <Invest />
            </RootLayout>
          }
        />
        <Route
          path="/invite"
          element={
            <RootLayout>
              <Invite />
            </RootLayout>
          }
        />
        <Route
          path="/join"
          element={
            <RootLayout>
              <Join />
            </RootLayout>
          }
        />
        <Route
          path="/give"
          element={
            <RootLayout>
              <Give />
            </RootLayout>
          }
        />
        <Route
          path="/MC_Template"
          element={
            <MC_Template />
          }
        />
        <Route
          path="/pledge"
          element={
            <RootLayout>
              <Pledge />
            </RootLayout>
          }
        />
        <Route
          path="/Refer"
          element={
            <RootLayout>
              <Refer />
            </RootLayout>
          }
        />
        <Route
          path="/shop"
          element={
            <RootLayout>
              <Shop />
            </RootLayout>
          }
        />
        <Route
          path="/sign-in-up"
          element={
            <RootLayout>
              <SignInUp />
            </RootLayout>
          }
        />
        <Route
          path="/vote"
          element={
            <RootLayout>
              <Vote />
            </RootLayout>
          }
        />
        <Route
          path="/terms-of-service"
          element={
            <RootLayout>
              <Terms />
            </RootLayout>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <RootLayout>
              <Privacy />
            </RootLayout>
          }
        />
        <Route path="/our-work" element={<RootLayout><OurWork /></RootLayout>} />
        <Route path="/BWSPDF" element={<RootLayout><BWSPDF /></RootLayout>} />
        <Route path="/mission" element={<RootLayout><MissionPDF /></RootLayout>} />
        <Route path="/NCE" element={<RootLayout><NCE /></RootLayout>} />
        <Route path="/OMU" element={<RootLayout><OMUPDF /></RootLayout>} />
      </Routes>
    </div>
  );
}

export default App;
