import React from 'react'

const Invite = () => {
  return (
    <>
      <div style={{ fontSize: "40px", marginTop: "200px" }}>Invite</div>
      <div style={{ fontSize: "40px", marginTop: "10px" }}>Coming Soon...</div>
    </>
  )
}

export default Invite