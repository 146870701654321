import MissionImage from '../assets/Mission.pdf';
import classes from '../styles/PDFStyles.module.css';

const MissionPDF = () => {
  return (
    <div className={classes.pdf}>
      <embed
        src={MissionImage}
        width="1200px"
        height="800px"
        type="application/pdf"
      />
    </div>
  )
}

export default MissionPDF