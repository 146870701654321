import React from 'react'

const SignInUp = () => {
  return (
    <>
      <embed
        src="https://fotmglobal.com/landing/newsletter"
        width="100%"
        height="800px"
      />
      {/* <div style={{ fontSize: "40px", marginTop: "200px" }}>Sign In/Up</div>
      <div style={{ fontSize: "40px", marginTop: "10px" }}>Coming Soon...</div> */}
    </>
  )
}

export default SignInUp