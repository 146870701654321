import { motion } from "framer-motion";
import classes from '../styles/Banner.module.css';
import Image from '../assets/fist.jpg';
import pledgeIcon from '../assets/pledge.jpeg';


const Banner = () => {
  return (
    <div className={"home-img-container"}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeIn", duration: 2.5 }}
        className={classes.motion_div}
      >
        <img
          src={Image}
          alt="woman with fist raised"
          className={classes["home-img"]}
        />
      </motion.div>
      <div className={classes.textBox}>
        {/* <h2>PUTTING CORPORATE AMERICA ON NOTICE</h2> */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: "easeIn", duration: 3, delay: 0.75 }}
          className={classes.motion_div}
        >
          <h1>Our Money United</h1>
        </motion.div>
        {/* <br />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeIn", duration: 3, delay: 2.5 }}
        className={classes.motion_div}
      >
        <p>Vote with your wallet.</p>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeIn", duration: 2.5, delay: 3.85 }}
        className={classes.motion_div}
      >
        <p className={classes.bigger}>
          <b>Change the world.</b>
        </p>
      </motion.div> */}
        {/* <div className={classes["search-dropdown"]}>
          <div className={classes["search-option-1"]}>
            <h2 className={classes.searchTitle1}>Shop Now- Free</h2>
            <button type="submit">
              <FontAwesomeIcon icon={faMagnifyingGlass} className={ classes.icon1} />
            </button>
          </div>
          <div className={classes["search-option-2"]}>
            <h2 className={classes.searchTitle2}>Shop Now</h2>
            <button type="submit">
              <FontAwesomeIcon icon={faMagnifyingGlass} className={ classes.icon2}/>
            </button>
          </div>
        </div> */}
      </div>
      <>
        <a href="/pledge" className={classes["pledge-button-container"]}>
          <div className={classes["pledge-button"]}>
            <img
              src={pledgeIcon}
              alt="icon"
              width="150"
              height="150"
              className={classes.pledgeIcon}
            />
          </div>
          <h4 className={classes["pledge-button-header"]}>Take Pledge</h4>
        </a>
      </>
    </div>
  );
};

export default Banner;
