import OMUImage from "../assets/OMUPDF.pdf";
import classes from '../styles/PDFStyles.module.css';

const OMUPDF = () => {
  return (
    <div className={classes.pdf}>
      <embed
        src={OMUImage}
        width="1200px"
        height="800px"
        type="application/pdf"
      />
    </div>
  );
};

export default OMUPDF;
