
import classes from '../styles/Button.module.css'
import partnersIcon from "../assets/partners.png";
import referIcon from "../assets/referral.png";
import crowdIcon from "../assets/crowd.png";
import creditCardIcon from "../assets/creditCard.png";
import lovingIcon from "../assets/loving.png";
import stockmarketIcon from "../assets/stockmarket.png";
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';

interface MyComponentProps {
  title: string;
  action: string;
  path: string;
  icon: string;
  bgColor: string;
}

const Button = ({ title, action, path, icon, bgColor }: MyComponentProps) => {
  const jumpVariants = {
    hover: { scale: 1 },
  };

  return (
    <Link to={path} className={classes["content-button-container"]}>
      {icon ? (
        <motion.div
          whileHover="hover"
          whileTap="tap"
          variants={jumpVariants}
          transition={{ duration: 0.2 }}
        >
          <div className={classes["icon-container"]}>
            <h3 className={classes["icon-title"]}>{title}</h3>
            {title === "Join" ? (
              <>
                <img
                  src={crowdIcon}
                  alt="icon"
                  width="150"
                  height="150"
                  className={classes.icon}
                />
                <div className={classes["title-container"]}>
                  <p className={classes["action-title"]}>{action}</p>
                </div>
              </>
            ) : null}
            {title === "Shop" ? (
              <>
                <img
                  src={creditCardIcon}
                  alt="icon"
                  width="150"
                  height="150"
                  className={classes.icon}
                />
                <div className={classes["title-container"]}>
                  <p className={classes["action-title"]}>{action}</p>
                </div>
              </>
            ) : null}
            {title === "Invite" ? (
              <>
                <img
                  src={partnersIcon}
                  alt="icon"
                  width="150"
                  height="150"
                  className={classes.icon}
                />
                <div className={classes["title-container"]}>
                  <p className={classes["action-title"]}>{action}</p>
                </div>
              </>
            ) : null}
            {title === "Refer" ? (
              <>
                <img
                  src={referIcon}
                  alt="icon"
                  width="150"
                  height="150"
                  className={classes.icon}
                />
                <div className={classes["title-container"]}>
                  <p className={classes["action-title"]}>{action}</p>
                </div>
              </>
            ) : null}
            {title === "Give" ? (
              <>
                <img
                  src={lovingIcon}
                  alt="icon"
                  width="150"
                  height="150"
                  className={classes.icon}
                />
                <div className={classes["title-container"]}>
                  <p className={classes["action-title"]}>{action}</p>
                </div>
              </>
            ) : null}
            {title === "Invest" ? (
              <>
                <img
                  src={stockmarketIcon}
                  alt="icon"
                  width="150"
                  height="150"
                  className={classes.icon}
                />
                <div className={classes["title-container"]}>
                  <p className={classes["action-title"]}>{action}</p>
                </div>
              </>
            ) : null}
          </div>
        </motion.div>
      ) : (
        <motion.div
          whileHover="hover"
          whileTap="tap"
          variants={jumpVariants}
          transition={{ duration: 0.2 }}
        >
          <div
            className={classes["background-container"]}
            style={{ backgroundColor: bgColor }}
          >
            <h3 className={classes["backup-text"]}>{action}</h3>
          </div>
        </motion.div>
      )}
    </Link>
  );
};

export default Button;
