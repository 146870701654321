import classes from "../styles/FP-Content-Below.module.css";
import { FPCard, FPCard2 } from './FP-Card';
import voteWalletImage from "../assets/voteWallet.jpg";

const cardInfo = [
  {
    header: "",
    content: (
      <p>
        Dr. Martin Luther King was considered the most dangerous man in America. His famous speech in 1963 was based upon his newfound
        revelation that civil and voting rights were not enough. He assembled
        allies with common values and called on them to leverage their
        collective spending power to change the world.
      </p>
    ),
    image: "/../public/assets/MLKJr.jpg",
    alt: "MLK Jr",
    link: "#",
  },
  {
    header: '"Our Money United" Campaign',
    content: (
      <p>
        Friends of the Movement has launched OMU to introduce the world’s first
        conscious spending platform, the “Digital Voter Wallet” (DVW). Consumers
        are called to action to take a pledge to vote with their wallets to
        support causes and merchants that align with their values.
      </p>
    ),
    image: "/../public/assets/MLKJr.jpg",
    alt: "MLK Jr",
    link: "#",
  },
  {
    header: "TOGETHER = IMPACT",
    content: (
      <p>
        The Digital Voter Wallet unites spending power and gives consumers the
        ability to conveniently invite friends of the Movement & aligned
        merchants into an economy they own to build conscious communities and
        hold corporations accountable.
      </p>
    ),
    image: "/../public/assets/MLKJr.jpg",
    alt: "MLK Jr",
    link: "#",
  },
  {
    header: "Putting Corporate America on Notice",
    content: (
      <p>
        If you are not a friend, what are you? Consumers will hold corporations
        accountable by spending ONLY with friends listed in the DVW that have
        made a pledge to be track based upon their spending, giving and
        investing to help close the wealth gap. Friend’s progress is reported
        for the world to see.
      </p>
    ),
    image: "/../public/assets/MLKJr.jpg",
    alt: "MLK Jr",
    link: "#",
  },
];

const cardInfo2 = [
  {
    header: "Pledge and Education",
    content: (
      <p>
        The Conscious Spending Pledge drives accountability for conscious groups
        ,their allies and corporate friends. Consumers want to know more about
        the companies they shop with. Impact Central, is a digital community
        that offers third party training resources, guidance and powerful voter
        activities to create direct impact and grow conscious businesses.
      </p>
    ),
    image: "/../public/assets/MLKJr.jpg",
    alt: "MLK Jr",
    link: "#",
  },
  {
    header: "Search, Shop, Refer, Give and Invest",
    content: (
      <p>
        Our network of Friends (consumers, merchants, and advocates) is being
        built from the ground up in Conscious Cities across America through the
        Our Money United Campaign. The Digital Voter Wallet allows Friends to
        instantly search and shop or not shop with merchants that match their
        values, creating a boycott where Friends only spend with those that are
        supporting their community.
      </p>
    ),
    image: "/../public/assets/MLKJr.jpg",
    alt: "MLK Jr",
    link: "#",
  },
  {
    header: "Spending Ticker and Index",
    content: (
      <p>
        What Gets Measured, Gets done. Our Conscious Spending Tickers Puts
        Corporate America on notice that Friends of the Movement are watching
        their progress on eliminating racial disparities and societal gaps.
        We’re amplifying your impact through “friendly data" to revolutionize
        the full potential of “Our Money United to change the world.
      </p>
    ),
    image: "/../public/assets/MLKJr.jpg",
    alt: "MLK Jr",
    link: "#",
  },
  // {
  //   header: "Friends",
  //   content: (
  //     <p>
  //       <ul>
  //         <li>There have always been friends.</li>
  //         <li>If you aren’t a friend, what are you?</li>
  //         <li>Today More than Ever.</li>
  //       </ul>
  //     </p>
  //   ),
  //   image: "/../public/assets/MLKJr.jpg",
  //   alt: "MLK Jr",
  //   link: "#",
  // },
];

const FPContentBelow = () => {
  return (
    <div className={classes["container"]}>
      <div className={classes["content-container-header"]}>
        Just Imagine...<br></br>Our Money United{" "}
      </div>
      <div className={classes["content-container"]}>
        {cardInfo.map((item, index) => (
          <FPCard
            id={index}
            header={item.header}
            content={item.content}
            image={item.image}
            alt={item.alt}
            link={item.link}
          />
        ))}
      </div>
      <img
        src={voteWalletImage}
        alt="vote with your wallet"
        width="2000"
        height="2000"
        className={classes["second-pic"]}
      />
      <div className={classes["content-container-header"]}>
        Everything You Need to Vote With Your Wallet
      </div>
      <div className={classes["content-container"]}>
        {cardInfo2.map((item, index) => (
          <FPCard2
            id={index}
            header={item.header}
            content={item.content}
            image={""}
            alt={item.alt}
            link={item.link}
          />
        ))}
      </div>
    </div>
  );
};

export default FPContentBelow;
