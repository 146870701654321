import classes from '../styles/Ticker.module.css';
import { motion } from "framer-motion";

type RowData = {
  title: string;
  amount: string;
};

const row1_dummy_data = [
  { title: "PUTTING CORPORATE AMERICA ON NOTICE", amount: "" },
  { title: "BWS SPEND", amount: "230,756,984" },
  { title: "BWS GIVE", amount: "1,723,865" },
  { title: "BWS INVEST", amount: "2,413,828" },
  { title: "LATINO SPEND", amount: "230,756,984" },
  { title: "LATINO GIVE", amount: "1,723,865" },
  { title: "WOMEN INVEST", amount: "2,413,828" },
  { title: "WOMEN SPEND", amount: "230,756,984" },
  { title: "ALLY SPEND", amount: "230,756,984" },
  { title: "CORPORATE SPEND", amount: "1,723,865" },
  { title: "ECO SPEND", amount: "2,413,828" },
  { title: "ECO GIVE", amount: "1,723,865" },
  { title: "ECO INVEST", amount: "2,413,828" },
];

const row2_dummy_data = [
  { title: "PUTTING CORPORATE AMERICA ON NOTICE", amount: "" },
  { title: "WOMEN INVEST", amount: "2,413,828" },
  { title: "WOMEN SPEND", amount: "230,756,984" },
  { title: "ECO SPEND", amount: "2,413,828" },
  { title: "ECO GIVE", amount: "1,723,865" },
  { title: "ECO INVEST", amount: "2,413,828" },
  { title: "ALLY SPEND", amount: "230,756,984" },
  { title: "CORPORATE SPEND", amount: "1,723,865" },
  { title: "BWS SPEND", amount: "230,756,984" },
  { title: "BWS GIVE", amount: "1,723,865" },
  { title: "BWS INVEST", amount: "2,413,828" },
  { title: "LATINO SPEND", amount: "230,756,984" },
  { title: "LATINO GIVE", amount: "1,723,865" },
];

const Ticker = () => {
  const row1Text = row1_dummy_data
    .map((item: RowData) =>
      item.title === "PUTTING CORPORATE AMERICA ON NOTICE"
        ? `${item.title}`
        : `${item.title}: ${item.amount}`
    )
    .join(" | ");

  const row2Text = row2_dummy_data
    .map((item: RowData) =>
      item.title === "PUTTING CORPORATE AMERICA ON NOTICE"
        ? `${item.title}`
        : `${item.title}: ${item.amount}`
    )
    .join(" | ");

  return (
    <div className={classes["ticker-container"]}>
      <div className={classes["ticker-header"]}>
        <div className={classes["ticker-header1"]}>FotM INTRODUCES …</div>
        <div className={classes["ticker-header2"]}>
        THE BLACK WALL STREET (BWS) IMPACT TICKER
        </div>
      </div>
      <motion.div
        style={{ whiteSpace: "nowrap" }}
        initial={{ x: "-175%" }}
        animate={{ x: "100%" }}
        transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
      >
        <div className={classes["ticker-row1"]}>{row1Text}</div>
      </motion.div>
      <motion.div
        style={{ whiteSpace: "nowrap" }}
        initial={{ x: "-175%" }}
        animate={{ x: "100%" }}
        transition={{
          duration: 27,
          delay: 1,
          repeat: Infinity,
          ease: "linear",
        }}
      >
        <div className={classes["ticker-row2"]}>{row2Text}</div>
      </motion.div>
    </div>
  );
};

export default Ticker;
