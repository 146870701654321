import React from "react";
import BWSOriginImage from "../assets/BWSOrigin.pdf";
import classes from '../styles/PDFStyles.module.css';

const OurWork = () => {
  return (
    <div className={classes.pdf}>
      <embed
        src={BWSOriginImage}
        width="1200px"
        height="800px"
        type="application/pdf"
      />
    </div>
  );
};

export default OurWork;
