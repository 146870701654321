import classes from '../../styles/MainNav.module.css';
import logo from '../../assets/logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (showSearch) {
        setShowSearch(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [showSearch]);

  return (
    <>
      <header className={classes.header}>
        <div>
          <Link to="/">
            <img src={logo} alt="logo" className={classes.logo} />
          </Link>
        </div>
        <nav className={classes.navbar}>
          <ul>
            <div className={classes.dropdown}>
              <li>
                <Link to="/our-work">OUR WORK</Link>
              </li>
            </div>
            <div className={classes.dropdown}>
              <li>
                <Link to="/sign-in-up" className={classes.getInvolved}>
                  GET INVOLVED
                </Link>
                <div className={classes["dropdown-content"]}>
                  <Link to="/sign-in-up">PLEDGE</Link>
                  <Link to="/sign-in-up">VOTE</Link>
                  <Link to="/sign-in-up">REFER</Link>
                </div>
              </li>
            </div>
            <div className={classes.dropdown}>
              <li>
                <Link to="https://www.blackWallstreetcard.com">BLACK WALLSTREET</Link>
              </li>
            </div>
            <div className={classes.dropdown}>
              <li>
                <Link to="/sign-in-up">REGISTER FOR BETA</Link>
              </li>
            </div>
          </ul>
          {/* <button
            className={classes["search-icon"]}
            onClick={() => setShowSearch(!showSearch)}
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </button> */}
        </nav>
        {/* {mobile && <div className={classes["nav-sub-header"]}>The World's First Conscious Spending Platform</div>} */}
      </header>
      {showSearch && (
        <div className={classes["search-dropdown"]}>
          <div className={classes["search-option-1"]}>
            <h2 className={classes.searchTitle1}>Shop Now- Free</h2>
            <input type="text" placeholder="Search..." />
            <button type="submit">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
          </div>
          <div className={classes["search-option-2"]}>
            <h2 className={classes.searchTitle2}>Shop Now [Members ONLY]</h2>
            <input type="text" placeholder="Search..." />
            <button type="submit">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
